import store from '../store/index'
/**
 * Class 
 */
class Invoice {
    static calculPUHT({
        is_specific,
        is_exonere,
        price,
        reduction,
        tva,
        puht
    } ) {
        let total = 0;
        if (is_specific) {
            if (store.state.auth.company.setting.regime_fiscal.toLowerCase() === "reel") {
                if (!is_exonere) {
                    total = (( parseFloat(price.ttc) - parseFloat(price.tax2) - parseFloat(price.tax1) - parseFloat(reduction)) / (1 + (parseFloat(tva)/100))) - parseFloat( price.ts);
                }else{
                    total = (( parseFloat(price.ttc) -  parseFloat(price.tax2) - parseFloat(price.tax1)  -  parseFloat(reduction))) - parseFloat( price.ts) ;
                }
            }

            if (store.state.auth.company.setting.regime_fiscal.toLowerCase() === "tps") {
                if (!is_exonere) {
                    total = parseFloat(price.ttc) - parseFloat(reduction) - parseFloat(price.ts);
                }else{
                    total = parseFloat(price.ttc) - parseFloat(reduction) - parseFloat(price.ts);
                }
            }
        }else{
            if (store.state.auth.company.setting.regime_fiscal.toLowerCase() === "reel") {
                total =  parseFloat(puht);
            }

            if (store.state.auth.company.setting.regime_fiscal.toLowerCase() === "tps") {
                total =  parseFloat(puht);
            }
        }

        return total
    }

    static calculPUTTC({
        is_specific,
        is_exonere,
        is_not_details,
        tva,
        price,
        reduction,
        puht
    }){
        let total
        if (is_specific) {
            if (is_not_details) {
                total = parseFloat(puht)
            } else {
                if(store.state.auth.company.setting.regime_fiscal.toLowerCase() === 'tps')
                {
                    total = parseFloat(price.ttc) - parseFloat(reduction);
                }

                if(store.state.auth.company.setting.regime_fiscal.toLowerCase() === 'reel')
                {
                    total = parseFloat(price.ttc) - parseFloat(reduction);
                }
            }
        }else{
            if(store.state.auth.company.setting.regime_fiscal.toLowerCase() === 'tps')
            {
                total = parseFloat(puht)
            }

            if(store.state.auth.company.setting.regime_fiscal.toLowerCase() === 'reel')
            {
                if(!is_exonere){
                    total = parseFloat(puht) * (1 + (tva/100))
                }else{
                    total = parseFloat(puht)
                }
            }
        }
        return total
    }

    static calculMTTC({
        puttc,
        qte
    }){
        let total =  parseFloat(puttc) * parseFloat(qte)

        return isNaN(total) ? 0 : total
    }
}

export default Invoice;